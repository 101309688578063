<template>
	<div  class="pos-relative t-center flex-center flex w-100 pd-thin">
		<video ref="videoElement"  class="pos-absolute pos-t-0 pos-l-0 radius-big h-100 object-fit-cover w-100 video-height" preload autoplay muted loop playsinlineclass playsinline src="/nye2025/bg.mp4" itemprop="video" type="video/mp4" ></video>

		<!-- <img src='/nye2025/bg-2.jpg' class="pos-absolute pos-t-0 pos-l-0 radius-big h-100 object-fit-cover w-100 video-height"> -->

	  <div style="max-width: min(100%, 40rem)" class="bg-blur-semi bg-white-transp-20 t-white flex-column pd-medium radius-big  flex w-100 h-100 gap-zero">
    	<h3 class="t-unna mn-b-thin">
				{{t('nye2024.subtitle')}}
	    </h3>

	    <hr class="bg-white-transp-20 mn-t-small mn-b-small d-block">

    	<h2 class="t-unna ">
				{{t('nye2024.title')}}
	    </h2>

	    <hr class="bg-white-transp-20 mn-t-small mn-b-small d-block">

	    <SectionBookingForm
	    	:options="options"
	    />

	    <!-- <div class="flex-nowrap flex gap-thin">
	    	<a href="#bookingform" class="t-black p-big w-100 pd-medium radius-extra uppercase t-medium bg-white">
	    		SOLD OUT
	    	</a>
	    </div> -->
	  </div>

	</div>
</template>


<script setup>

	import { ref, onMounted } from 'vue';

	import { useI18n } from 'vue-i18n'

	import SectionBookingForm from '@/components/sections/SectionBookingForm.vue'

	const props = defineProps([
	  'content',
	  'options'
	])

	
	const { t } = useI18n({ 
		messages: {
		  en:{
		    "nye2024":{
		    	"subtitle": "BOOK YOUR TABLE",
		      "title":"Surrounded by palm trees under the starry sky",
		      "description":"If you’re looking for an extraordinary environment for a memorable New Year’s Eve celebration that easily combines modern luxury with tropical allure, then your search ends here at The Commune.",
		      "booknow":"Book Now"
		    }
		  }
		}
	})


	const videoElement = ref(null)

	var isPlaying = false;

	function	checkAndPlayVideo() {
    // Initializing values

		// On video playing toggle values
		videoElement.value.onplaying = function() {
		  isPlaying = true;
		};

		// On video pause toggle values
		videoElement.value.onpause = function() {
		  isPlaying = false;
		};	
  }

  // Play video function
	async function playVid() {      
    if (videoElement.value.paused && !isPlaying) {
      return videoElement.value.play();
    }
	} 
  
	onMounted(() => {
		checkAndPlayVideo()
		playVid()
	})
</script>

<style lang="scss">
</style>
