import { createSSRApp, createApp, provide } from 'vue';
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
// App
import App from './components/App.vue';
// Router
import { _createRouter } from './router';
// Store
import store from './store';
// Env
import { setupEnvironment } from './env';
// Localization i18n
import i18n from "@/localization/vue-i18n";

import './scss/theme.scss'

// //////////////////////////////////////////////////////
// App Instance Creation
// //////////////////////////////////////////////////////
export function _createApp() {

  const app 
    = process.env.MOBILE_APP 
    ? createApp(App) 
    : createSSRApp(App);


  let MOBILE_APP = (process.env.MOBILE_APP === "true")

  const envVariables = [
    'NODE_ENV',
    'PORT',
    'APP_NAME',
    'DOMAIN_URL',
    'API_URL'
  ];

  for (const prop of envVariables) {
    app.config.globalProperties[prop] = process.env[prop];
    app.provide(prop.toString(), app.config.globalProperties[prop]);
  }

  const router = _createRouter(); 
  const meta = createMetaManager(true);
  
  app
    .use(router)
    .use(i18n)
    .use(meta)
    .use(metaPlugin);

  if (process.env.MOBILE_APP)  { 
    app.mount("#app");
  } else {
    return { 
      app, 
      router, 
      store, 
      i18n, 
      meta, 
      metaPlugin  
    }
  }
};
