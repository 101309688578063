<template>
  <router-view v-slot="{ Component }">
      <Suspense>
          <component :is="Component"/>
      </Suspense>
  </router-view>
</template>

<script setup>
    import { useMeta } from 'vue-meta'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { computed } from 'vue'

    const { t, locale } = useI18n({ 
        messages: {
            "en":{
                "title":"The Commune – Viewpoint Terrace & Bar at Phuket",
                "description":"Experience the best of Phuket at The Commune, a stylish bar and terrace located on Karon beach.",
                "keywords":"Bar, Terrace, Phuket, Karon Beach, Cocktails, Dining, Sea View, Parties, Events, Chill-Out Zone",
                "og":{
                    "title":"The Commune – Viewpoint Terrace & Bar at Phuket",
                    "description":"Experience the best of Phuket at The Commune, a stylish bar and terrace located on Karon beach. Enjoy breathtaking views of the sea while sipping on delicious cocktails and savoring delectable dishes from our menu.",
                    "image":"Image Path"
                },
                "twitter":{
                    "title":"The Commune – Viewpoint Terrace & Bar at Phuket",
                    "description":"Experience the best of Phuket at The Commune, a stylish bar and terrace located on Karon beach.",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"en"
                }
            },
            "ru":{
                "title":"The Commune – Терраса и Бар с видом в Пхукете",
                "description":"Познакомьтесь с лучшим, что есть в Пхукете, в стильном баре и террасе The Commune, расположенных на пляже Карон.",
                "keywords":"Бар, Терраса, Пхукет, Пляж Карон, Коктейли, Ресторан, Вид на море, Вечеринки, События, Зона отдыха",
                "og":{
                    "title":"The Commune – Терраса и Бар с видом в Пхукете",
                    "description":"Познакомьтесь с лучшим, что есть в Пхукете, в стильном баре и террасе The Commune, расположенных на пляже Карон. Наслаждайтесь захватывающим видом на море, попивая вкусные коктейли и наслаждаясь изысканными блюдами из нашего меню.",
                    "image":"/favicon/cover.jpg"
                },
                "twitter":{
                    "title":"The Commune – Терраса и Бар с видом в Пхукете",
                    "description":"Познакомьтесь с лучшим, что есть в Пхукете, в стильном баре и террасе The Commune, расположенных на пляже Карон.",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"ru"
                }
            },
            "fr":{
                "title":"The Commune – Terrasse et Bar avec vue à Phuket",
                "description":"Découvrez le meilleur de Phuket au The Commune, un bar et une terrasse chic situés sur la plage de Karon.",
                "keywords":"Bar, Terrasse, Phuket, Plage de Karon, Cocktails, Restaurant, Vue sur la mer, Fêtes, Événements, Zone Chill-Out",
                "og":{
                    "title":"The Commune – Terrasse et Bar avec vue à Phuket",
                    "description":"Découvrez le meilleur de Phuket au The Commune, un bar et une terrasse chic situés sur la plage de Karon. Profitez de la vue imprenable sur la mer tout en dégustant de délicieux cocktails et des plats succulents de notre menu.",
                    "image":"/favicon/cover.jpg"
                },
                "twitter":{
                    "title":"The Commune – Terrasse et Bar avec vue à Phuket",
                    "description":"Découvrez le meilleur de Phuket au The Commune, un bar et une terrasse chic situés sur la plage de Karon.",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"fr"
                }
            },
            "de":{
                "title":"The Commune – Terrasse und Bar mit Aussicht in Phuket",
                "description":"Erleben Sie das Beste von Phuket im The Commune, einer stilvollen Bar und Terrasse am Karon Strand.",
                "keywords":"Bar, Terrasse, Phuket, Karon Strand, Cocktails, Speisen, Meerblick, Partys, Events, Chill-Out Zone",
                "og":{
                    "title":"The Commune – Terrasse und Bar mit Aussicht in Phuket",
                    "description":"Erleben Sie das Beste von Phuket im The Commune, einer stilvollen Bar und Terrasse am Karon Strand. Genießen Sie atemberaubende Aussichten auf das Meer, während Sie köstliche Cocktails schlürfen und köstliche Gerichte von unserer Speisekarte probieren.",
                    "image":"/favicon/cover.jpg"
                },
                "twitter":{
                    "title":"The Commune – Terrasse und Bar mit Aussicht in Phuket",
                    "description":"Erleben Sie das Beste von Phuket im The Commune, einer stilvollen Bar und Terrasse am Karon Strand.",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"de"
                }
            },
            "it":{
                "title":"The Commune – Terrazza e Bar con vista a Phuket",
                "description":"Vivi il meglio di Phuket a The Commune, un elegante bar e terrazza situati sulla spiaggia di Karon.",
                "keywords":"Bar, Terrazza, Phuket, Spiaggia di Karon, Cocktails, Ristorazione, Vista sul mare, Feste, Eventi, Zona Chill-Out",
                "og":{
                    "title":"The Commune – Terrazza e Bar con vista a Phuket",
                    "description":"Vivi il meglio di Phuket a The Commune, un elegante bar e terrazza situati sulla spiaggia di Karon. Goditi una vista mozzafiato sul mare sorseggiando deliziosi cocktail e gustando i piatti squisiti del nostro menu.",
                    "image":"/favicon/cover.jpg"
                },
                "twitter":{
                    "title":"The Commune – Terrazza e Bar con vista a Phuket",
                    "description":"Vivi il meglio di Phuket a The Commune, un elegante bar e terrazza situati sulla spiaggia di Karon.",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"it"
                }
            },
            "zh":{
                "title":"The Commune – 在普吉岛的观景露台与酒吧",
                "description":"在位于卡伦海滩的时尚酒吧和露台The Commune，体验普吉岛的最佳之处。",
                "keywords":"酒吧, 露台, 普吉岛, 卡伦海滩, 鸡尾酒, 餐饮, 海景, 派对, 活动, Chill-Out 区",
                "og":{
                    "title":"The Commune – 在普吉岛的观景露台与酒吧",
                    "description":"在位于卡伦海滩的时尚酒吧和露台The Commune，体验普吉岛的最佳之处。在品尝我们菜单上美味的鸡尾酒和美食时，欣赏海洋的壮丽景色。",
                    "image":"/favicon/cover.jpg"
                },
                "twitter":{
                    "title":"The Commune – 在普吉岛的观景露台与酒吧",
                    "description":"在位于卡伦海滩的时尚酒吧和露台The Commune，体验普吉岛的最佳之处。",
                    "image":"/favicon/cover.jpg",
                    "card":"summary_large_image"
                },
                "htmlAttrs":{
                    "lang":"zh"
                }
            }
        }
    })

    const route = useRoute();
  
    const languages = ['en', 'ru', 'de', 'it', 'fr', 'zh'];
    const base_url = 'https://thecommunephuket.com';


    function removeTrailingSlash(url) {
      return url.endsWith('/') ? url.slice(0, -1) : url;
    }

    // Используйте эту функцию, чтобы удалить слэш в конце URL
    const current_url = computed(() => removeTrailingSlash(`${base_url}${route.path}`));

    const generateAlternateLinks = computed(() => {
      return languages.map(lang => {
        let newPath = route.path;
        if (route.params.locale) {
          newPath = newPath.replace(`/${route.params.locale}`, `/${lang}`);
        } else {
          newPath = `/${lang}${newPath}`;
        }
        const href = removeTrailingSlash(`${base_url}${newPath}`);
        return { 
          hid: 'alternate', 
          rel: 'alternate', 
          hreflang: lang, 
          href: href
        };
      });
    });

    const { meta } = useMeta({
        title: t('title'),
        description: t('description'),
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
            { hid: 'description', name: 'description', content: t('description') },
            { hid: 'keywords', name: 'keywords', content: t('keywords') },
            { hid: 'og:title', property: 'og:title', content: t('og.title') },
            { hid: 'og:description', property: 'og:description', content: t('og.description') },
            { hid: 'og:image', property: 'og:image', content: base_url + '/favicon/cover.jpg' },
            { hid: 'og:url', property: 'og:url', content: current_url.value },
            { hid: 'twitter:title', name: 'twitter:title', content: t('twitter.title') },
            { hid: 'twitter:description', name: 'twitter:description', content: t('twitter.description') },
            { hid: 'twitter:image', name: 'twitter:image', content: base_url + '/favicon/cover.jpg' },
            { hid: 'twitter:card', name: 'twitter:card', content: t('twitter.card') },
            ],
        link: [
            { hid: 'canonical', rel: 'canonical', href: current_url.value },
            ...generateAlternateLinks.value,
        ],
        htmlAttrs: { lang: t('htmlAttrs.lang')}
    })
</script>

<style lang="scss">
</style>
