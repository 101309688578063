import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
} from 'vue-router';

import i18n from "@/localization/vue-i18n";

import landing 			from './routes/landing';
import layoutClient from '/src/components/layout/Client.vue'

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [
  {
   	path: '/:locale?',

  	component: layoutClient,

  	meta: {
  		title: {
  			en: "Home",
  			ru: "Главная",
  		},
      menu: false
  	},

    children: [
    	...landing,
      {
        name: 'NYE2024',
        path: 'special/nye2024',
        component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/nye2024/Home.vue'),
      },
      {
        name: 'NYE2025',
        path: 'special/nye2025',
        component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/nye2025/Home.vue'),
      }
    ]
  }, 
  {
    path: '/404',
    name: 'notfound',
    component: () => import('@/components/pages/404.vue')
  },
  {
    path: '/401',
    name: 'unauthorized',
    component: () => import('@/components/pages/404.vue')
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/components/pages/404.vue') },
  
];

const scrollBehavior = (to, from, savedPosition) => {

  const position = {}
  // new navigation.
  // scroll to anchor by returning the selector
  if (to.hash) {
   position.selector = to.hash
  }
  // check if any matched route config has meta that requires scrolling to top
  if (to.matched.some(m => m.meta.scrollToTop)) {
   // cords will be used if no selector is provided,
   // or if the selector didn't match any element.
   position.x = 0
   position.y = 0
  }
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  return position
}

export function _createRouter() {
  return createRouter({ routes, history });
}